var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table  ui-table tw-table",attrs:{"border":"1"}},[_vm._t("thead",function(){return [_c('thead',{staticClass:"tw-bg-blue-500 tw-text-white"},[_c('tr',[(_vm.showCheckbox)?_c('CheckableHead'):_vm._e(),(_vm.expandable)?_c('ExpandableHead'):_vm._e(),(!_vm.showCheckbox)?_c('th',{staticClass:"tw-px-6 tw-py-3  tw-uppercase tw-text-center tw-w-3 page-header tw-border"},[_vm._v(" "+_vm._s(_vm.$t("numeric"))+" ")]):_vm._e(),_vm._t("columns",function(){return [_vm._l((_vm.columns),function(column,key){return [(_vm.showColumn(column))?_c('th',{key:key,staticClass:"tw-px-6 tw-py-3 tw-text-left  tw-uppercase tw-whitespace-nowrap page-header tw-border tw-border-gray-400",class:_vm.showColumnClass(column),style:(_vm.showColumnStyle(column)),attrs:{"scope":"col"}},[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('p',{staticClass:"tw-mb-0"},[_vm._v(" "+_vm._s(_vm.showColumnName(column)))]),_c('ColumnFilter',{attrs:{"column":column,"sortedColumn":_vm.sortedColumn},on:{"sort":_vm.setSortedColumn}})],1)]):_vm._e()]})]})],2),_c('TableProgressBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1)]}),_vm._t("tbody",function(){return [_c('tbody',{staticClass:"tw-bg-white tw-divide-y tw-divide-gray-200"},[_vm._l((_vm.dataTable),function(items,itemKey){return [_c('ShouldGroup',{key:itemKey,attrs:{"items":items,"itemKey":itemKey},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var record = ref.record;
var index = ref.index;
var numeric = ref.numeric;
return [_c('tr',{key:_vm.keyName !== null ? record[_vm.keyName] : index,staticClass:"hover:tw-bg-blue-100 tw-transform tw-duration-200",class:{ 
                                    'tw-line-through tw-text-red-600': record.is_deleted ? record.is_deleted : false,
                                    'tw-bg-gray-100': numeric % 2 != 0,
                                    'tw-bg-blue-200': _vm.selectedIndex == numeric 
                                },style:({ cursor: _vm.mouseTypeOnRow }),on:{"click":function($event){return _vm.raiseRowClickEvent({ index: index, record: record })},"dblclick":function($event){return _vm.raiseRowDoubleClickEvent({ index: index, record: record })}}},[(_vm.showCheckbox)?_c('CheckableColumn',{attrs:{"record":record}}):_vm._e(),(_vm.expandable)?_c('ExpandableColumn',{attrs:{"index":index}}):_vm._e(),(!_vm.showCheckbox)?_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(numeric + 1))]):_vm._e(),_vm._t("default",null,{"record":record,"index":index})],2),(_vm.expandableItems[index])?_c('ExpandableRow',[_vm._t("expand",null,{"record":record,"index":index})],2):_vm._e()]}}],null,true)})]})],2)]}),_vm._t("tfooter")],2)}
var staticRenderFns = []

export { render, staticRenderFns }