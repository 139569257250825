import booking from "@/api/driver/booking-pickup";

import { baseState, baseMutations } from "@/store/mixins";

import { split, join } from "lodash";
import moment from "moment";

export const state = {
    ...baseState,
    formModels: {},
    isDelivery: 0,
    is_stock_order: undefined,
		status: '',
    dateRange: [moment().format("DD-MM-YYYY"), moment().format("DD-MM-YYYY")]
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    },
    SET_IS_DELIVERY(state, value) {
        state.isDelivery = value;
    },
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
    SET_STOCK_ORDER(state, value) {
        state.is_stock_order = value;
    },
    SET_STATUS(state, value) {
        state.status = value;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
			console.log(context.state.dateRange);
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    is_stock_order: context.state.is_stock_order
                        ? true
                        : undefined,
                    search: context.state.search,
                    is_delivery: context.state.isDelivery,
                    status: context.state.status,
                    // date_between: !context.state.search ? join(context.state.dateRange, ",") : undefined // bong dara request search ignore date
                    date_between: 
                        context.state.dateRange.length > 0
                            ? join(context.state.dateRange, ",")
                            : undefined
                }
            },
            attributes
        );
        return booking.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return booking.store(data);
    },
    request(context, data) {
        return booking.request(data);
    },
    update(context, { id, data }) {
        return booking.update(id, data);
    },
    destroy(context, id) {
        return booking.destroy(id);
    },
    upload(context, data) {
        return booking.uploadImage(data);
    },
    pickupInfo(context, id) {
        return booking.pickupInfo(id);
    },
    getChecked(context, { params }) {
        return booking.getChecked({ params: params });
    },
    confirmCheck(context, data) {
        return booking.confirmCheck(data);
    },
    assingDriver(context, data) {
        return booking.assingDriver(data);
    },
    history(context, id) {
        return booking.history(id);
    },
    async getFormViewData(context, { params, index }) {
        let response = await booking.formModel({ params: params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return response;
    },
    async formViewData(context, { params }) {
        return await booking.formModel({ params: params });
    }
};
