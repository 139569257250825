import httpClient from '@/http-client'

export const get = (options = {}) => {
  return httpClient
    .get('/api/re-deliveries', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const store = data => {
  return httpClient
    .post('/api/re-deliveries', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export const formModel = (options = {}) => {
  return httpClient
    .get('/api/re-deliveries/form-view-models', options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}


export default { get, store, formModel }