import reDelivery from "@/api/agency/re-delivery-api";

import { baseState, baseMutations } from "@/store/mixins";
import { join } from "lodash";
import moment from "moment";

export const state = {
    ...baseState,
    status: "",
    dateRange: [moment().format("DD-MM-YYYY"), moment().format("DD-MM-YYYY")]
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
    SET_STATUS(state, value) {
        state.status = value;
    }
};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                  date_between:
                      context.state.dateRange.length > 0
                          ? join(context.state.dateRange, ",")
                          : undefined,
                    search: context.state.search,
                    status: context.state.status
                }
            },
            attrubutes
        );
        return reDelivery.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return reDelivery.store(data);
    },
    getFormViewData(context, attributes = {}) {
        return reDelivery.formModel(attributes);
    }
};
