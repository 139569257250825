import clear from '@/api/finance/driver-clear-cash'

import { baseState, baseMutations } from '@/store/mixins'
import { join, split, mapKeys, camelCase } from 'lodash'
import moment from 'moment'

class PaginationAll {
  constructor(paging = {}) {
    this.currentPage = paging.currentPage ? parseInt(paging.currentPage) : 1
    this.lastPage = paging.lastPage ? parseInt(paging.lastPage) : 0
    this.perPage = paging.perPage ? parseInt(paging.perPage) : 15
    this.total = paging.total ? parseInt(paging.total) : 0
    this.from = this.perPage * (this.currentPage - 1) + 1
    this.to = this.perPage * this.currentPage
  }
}

export const state = {
  ...baseState,
  dateRange: [
		moment().format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
  dateRangeAll: [
		moment().format("DD-MM-YYYY"),
		moment().format("DD-MM-YYYY")
	],
  formModels: {
    driver: []
  },
  resourcesAll: [],
  paginateAll: new PaginationAll(),
  is_driver_clear_cash: null,
  agency_id: []
}

export const getters = {}

export const mutations = {
  ...baseMutations,
  SET_VIEW_MODELS(state, {data, index}){
    if(!index){
      state.formModels = data
    }else{
      split(index, ',').forEach(el => {
        state.formModels[el] = data[el]
      });
    }
  },
  SET_DATE_RANGE(state, date) {
		state.dateRange = date;
	},
  RECEIVE_RESOURCES_ALL(state, records) {
    const { data, ...record } = records
    state.resourcesAll = data
    state.paginateAll = new PaginationAll(mapKeys(record.meta, (v, k) => camelCase(k)))
  },
	SET_DATE_RANGE_ALL(state, date) {
		state.dateRangeAll = date;
	},
	SET_IS_DRIVER_CLEAR_CASH(state, value) {
		state.is_driver_clear_cash = value;
  },
  SET_SERVICE_AGENCY(state, value) {
    state.agency_id = value
    console.log(value)
  },
}

export const actions = {
  fetch(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          search: context.state.search,
          date_between: join(context.state.dateRange, ",")
        }
      },
      attributes
    )
    return clear.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  fetchAllData(context, attributes = {}) {
    let params = Object.assign(
      {
        page: context.state.paginateAll.page,
        perPage: context.state.paginateAll.perPage,
        filter: {
          search: context.state.search,
          is_driver_clear_cash: context.state.is_driver_clear_cash,
          date_between: join(context.state.dateRangeAll, ","),
          agency_id: context.state.agency_id,
        }
      },
      attributes
    )
    return clear.allData({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES_ALL', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return clear.store(data)
  },
  successPackage(context, { driverId, transactionDate }) {
    return clear.successPackage(driverId, transactionDate)
  },
  toPdf(context, clearId) {
    return clear.toPdf(clearId)
  },
  async getFormViewData(context, { params, index }) {
    let response = await clear.formModel({ params: params })
    if(response){
      context.commit('SET_VIEW_MODELS', { data: response.data, index: index })
    }
    return response;
  }
}
